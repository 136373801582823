<template>
	<div class="export">
		<TabHeader :active="5"></TabHeader>

		<canvas class="canvas" id="canvasId"></canvas>

		<!-- <img v-if="exportImg" :src="exportImg" alt="" srcset="" /> -->

		<div class="content">
			<!-- 按钮 -->
			<div style="margin-top: 10px">
				<!-- <el-button type="primary">手动添加</el-button> -->
				<el-button type="primary" @click="chooseBtn"> 选择添加 </el-button>
				<el-button type="primary" @click="exportTable(chooseList)">
					导出报表
				</el-button>
				<el-button type="primary" @click="exportExcel(chooseList)">
					导出Excel
				</el-button>
			</div>

			<div class="adjust" v-if="chooseList.length > 0">
				<span>{{ tabsList[activeIndex - 1] }}价格调整：</span>
				<el-select
					style="width: 140px; margin-left: 10px"
					v-model="adjustOptionVal"
					placeholder="请选择"
				>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>

				<div
					class="adjust_btn"
					:class="adjustBtnVal === '上调' ? 'adjust_btn_active' : ''"
					@click="adjustBtnVal = '上调'"
				>
					上调
				</div>
				<div
					class="adjust_btn"
					:class="adjustBtnVal === '下调' ? 'adjust_btn_active' : ''"
					@click="adjustBtnVal = '下调'"
				>
					下调
				</div>

				<div style="width: 150px; margin-left: 10px">
					<el-input
						v-model="adjustPrice"
						maxlength="7"
						type="text"
						size="small"
						placeholder="请输入价格"
						@input="inputChange"
					></el-input>
				</div>

				<el-button
					size="small"
					style="margin-left: 10px"
					type="primary"
					@click="adjustBtnDetermine"
				>
					确定
				</el-button>
			</div>

			<!-- 列表 -->
			<div class="list">
				<!-- 已选择列表 -->
				<div class="list_item" v-for="item in chooseList" :key="item.Id">
					<div class="list_item_name">{{ item.Title }}:</div>
					<div style="width: 160px">
						<el-input
							v-model="item.value"
							type="number"
							size="small"
							placeholder="请输入内容"
						></el-input>
					</div>
				</div>
			</div>
		</div>

		<el-dialog
			title=""
			top="5vh"
			:visible.sync="dialogVisible"
			:show-close="false"
			:close-on-click-modal="false"
			width="600px"
		>
			<!-- tabs -->
			<div class="tabs">
				<div class="tabs_tit">
					<!-- 地区选择 -->
					<div class="tabs_name">
						<div
							class="tabs_name_item"
							:class="activeIndex == index + 1 ? 'active' : ''"
							v-for="(item, index) in tabsList"
							:key="item"
						>
							<div v-if="activeIndex == index + 1">
								<el-checkbox
									v-model="CheckedAll"
									@change="CheckedAllChange"
								></el-checkbox>
							</div>
							<div
								style="width: fit-content; margin-left: 8px"
								@click="changeType(index + 1)"
							>
								{{ item }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 选择列表 -->
			<div class="choose_list">
				<el-container style="height: 70vh; border: 1px solid #eee">
					<el-aside width="200px" style="background-color: rgb(238, 241, 246)">
						<div
							class="title_name"
							:class="nameIndex == index ? 'title_name_active' : ''"
							v-for="(item, index) in titleList"
							:key="item"
							@click="changeCategory(index)"
						>
							{{ item }}
						</div>
					</el-aside>

					<el-main style="padding: 10px 20px">
						<div class="offer_name" style="text-align: right">
							<el-checkbox
								v-model="categoryAllChecked"
								@change="checkboxCategoryChange"
								>全选</el-checkbox
							>
						</div>
						<div class="offer_name" v-for="item in list" :key="item.Id">
							<el-checkbox
								v-model="item.checked"
								@change="checkboxChange($event, item)"
							>
								{{ item.Title }}
							</el-checkbox>
						</div>
					</el-main>
				</el-container>
			</div>

			<div slot="footer">
				<el-button type="warning" @click="dialogVisible = false"
					>取消</el-button
				>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import XLSX from "xlsx"
	export default {
		data() {
			return {
				defaultPic: "https://api.chajiuqu.com/uploadImage/img/defaultPict.png",

				title: "",
				exportImg: "",

				dialogVisible: false,

				allList: {},
				list: [],
				allTitleList: {},
				titleList: [],

				categoryAllChecked: false,
				CheckedAll: false,

				nameIndex: 0,

				chooseList: [],
				chooseCheckList: [],

				tabsList: [
					"流沙行情",
					"珠三角行情",
					"深圳A行情",
					"深圳B行情",
					"湖南行情"
				],
				activeIndex: 1,

				query: {
					pageindex: 1,
					category1: "全部",
					date: this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}"),
					isLogin: true,
					type: 1
				},

				priceChangeList: [0, 0, 0, 0, 0, 0, 0, 0, 0],
				adjustBtnVal: "上调",
				adjustPrice: "",
				options: [
					{
						value: 0,
						label: "全部"
					},
					{
						value: 1,
						label: "200以下"
					},
					{
						value: 2,
						label: "200-300"
					},
					{
						value: 3,
						label: "300-400"
					},
					{
						value: 4,
						label: "400-500"
					},
					{
						value: 5,
						label: "500-600"
					},
					{
						value: 6,
						label: "600-700"
					},
					{
						value: 7,
						label: "700-800"
					},
					{
						value: 8,
						label: "800以上"
					}
				],
				adjustOptionVal: 0
			}
		},

		async created() {
			await this.getList()
		},

		mounted() {
			this.chooseBtn()
		},

		methods: {
			// 获取报价列表
			async getList() {
				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.6)"
				})

				try {
					const { data: res } = await this.$api.index.getList(this.query)
					// console.log(res)

					this.query.date = this.$globalFun.parseTime(
						new Date(res.date),
						"{y}-{m}-{d}"
					)

					switch (this.query.type) {
						case 1:
							res.list.forEach((list) => {
								list.foods.forEach((item) => {
									item.value = item.OfferValue || 0
									item.checked = false
								})
							})
							break
						case 2:
							res.list.forEach((list) => {
								list.foods.forEach((item) => {
									item.value = item.ZSJValue || 0
									item.checked = false
								})
							})
							break
						case 3:
							res.list.forEach((list) => {
								list.foods.forEach((item) => {
									item.value = item.SZValue || 0
									item.checked = false
								})
							})
							break
						case 4:
							res.list.forEach((list) => {
								list.foods.forEach((item) => {
									item.value = item.SZValue2 || 0
									item.checked = false
								})
							})
							break
						case 5:
							res.list.forEach((list) => {
								list.foods.forEach((item) => {
									item.value = item.HNValue || 0
									item.checked = false
								})
							})
							break
					}

					const list = res.list.filter((item) => {
						return item.foods.length
					})
					// console.log(list[0].foods[0].value)

					if (!this.allList[this.activeIndex]) {
						this.allList[this.activeIndex] = list

						this.list = list[0].foods
						this.chooseCheckList = []
						this.nameIndex = 0
						this.CheckedAll = false

						this.titleList = list.map((item) => item.Name)

						this.allTitleList[this.activeIndex] = this.titleList

						this.getChecked()
						this.getCategoryAllChecked()
						// console.log(this.titleList);
					}
				} catch (error) {
					console.log(error)
				}

				loading.close()
			},

			async inputChange(e) {
				let price = e

				price = price.replace(/[^\d.]/g, "")
				price = price.replace(/^\00/g, "0")
				//确保第一个输入的是数字
				price = price.replace(/^\./g, "")
				//确保不能输入两个小数点
				price = price.replace(/\.{2,}/g, ".")
				//保证小数点只出现一次，而不能出现两次以上
				price = price.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				//确保只能输入三位小数
				price = price.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")

				if (price.indexOf(".") < 0 && price != "") {
					price = parseFloat(price) + ""
				}
				console.log(e, price)

				await await this.$nextTick()

				this.adjustPrice = price
			},

			async adjustBtnDetermine() {
				if (this.adjustPrice === "") {
					this.$message.warning("请先设置价格")
					return
				}

				console.log(
					this.adjustBtnVal,
					this.adjustOptionVal,
					this.priceChangeList
				)
				let price = 0
				if (this.adjustBtnVal === "上调") {
					price = this.adjustPrice - 0
				} else {
					price = 0 - this.adjustPrice
				}
				this.priceChangeList[this.adjustOptionVal] = price

				this.$message.success(
					`${this.options[this.adjustOptionVal].label}区间价格${
						this.adjustBtnVal
					}${this.adjustPrice}已设置`
				)
			},

			// 选择添加
			chooseBtn() {
				this.dialogVisible = true

				if (!this.allList[this.activeIndex]) {
					this.getList()
				}
			},

			// 切换地区
			changeType(e) {
				if (this.activeIndex === e) return

				this.activeIndex = e
				this.query.type = e

				if (!this.allList[e]) {
					this.getList()
				} else {
					this.list = this.allList[e][0].foods
					this.titleList = this.allTitleList[e]

					this.chooseCheckList = []
					this.nameIndex = 0
					this.CheckedAll = false

					this.getChecked()
					this.getCategoryAllChecked()
				}
			},

			// 切换类名
			changeCategory(e) {
				if (this.nameIndex == e) return

				this.nameIndex = e
				this.list = this.allList[this.activeIndex][e].foods
				this.getChecked()
				this.getCategoryAllChecked()
			},
			// 检查是否已选
			getChecked() {
				this.list.forEach((item) => {
					if (this.chooseCheckList.includes(item.Title)) {
						item.checked = true
					} else {
						item.checked = false
					}
				})
			},
			// 分类全选复选框状态
			getCategoryAllChecked() {
				const list = this.list.filter((item) => {
					return !this.chooseCheckList.includes(item.Title)
				})

				this.categoryAllChecked = list.length == 0
			},
			// 全选复选框状态
			getAllChecked() {
				let length = 0
				this.allList[this.activeIndex].forEach((list) => {
					length += list.foods.length
				})

				this.CheckedAll = length == this.chooseCheckList.length
			},
			// 全选
			CheckedAllChange(e) {
				if (e) {
					this.allList[this.activeIndex].forEach((list) => {
						list.foods.forEach((item) => {
							if (!this.chooseCheckList.includes(item.Title)) {
								this.chooseCheckList.push(item.Title)
							}
						})
					})
				} else {
					this.allList[this.activeIndex].forEach((list) => {
						list.foods.forEach((item) => {
							if (this.chooseCheckList.includes(item.Title)) {
								const index = this.chooseCheckList.indexOf(item.Title)
								this.chooseCheckList.splice(index, 1)
							}
						})
					})
				}

				this.getChecked()
				this.getCategoryAllChecked()
			},
			// 分类全选复选框改变
			checkboxCategoryChange(e) {
				if (e) {
					this.list.forEach((item) => {
						item.checked = true

						if (!this.chooseCheckList.includes(item.Title)) {
							this.chooseCheckList.push(item.Title)
						}
					})
				} else {
					this.list.forEach((item) => {
						item.checked = false

						if (this.chooseCheckList.includes(item.Title)) {
							const index = this.chooseCheckList.indexOf(item.Title)
							this.chooseCheckList.splice(index, 1)
						}
					})
				}

				this.getAllChecked()
			},
			// 复选框改变
			checkboxChange(e, item) {
				if (e) {
					if (!this.chooseCheckList.includes(item.Title))
						this.chooseCheckList.push(item.Title)
				} else {
					const index = this.chooseCheckList.indexOf(item.Title)
					if (index > -1) this.chooseCheckList.splice(index, 1)
				}

				this.getCategoryAllChecked()
				this.getAllChecked()
				// console.log(this.chooseCheckList);
			},
			// dialog选择确认
			confirm() {
				this.chooseList = []
				console.log(this.allList[this.activeIndex])
				this.allList[this.activeIndex].forEach((list) => {
					list.foods.forEach((item) => {
						if (this.chooseCheckList.includes(item.Title)) {
							this.chooseList.push(item)
						}
					})
				})

				this.dialogVisible = false
			},

			modifyList(lists) {
				const obj = {}
				lists.forEach((item) => {
					if (obj[item.Category2]) {
						obj[item.Category2].push(item)
					} else {
						obj[item.Category2] = [item]
					}
				})

				const list = []
				for (const key in obj) {
					list.push({
						category: key
					})
					list.push(...this.sortArr(obj[key]))
				}

				return list
			},

			sortArr(list) {
				// console.log(list)
				const len = list.length
				for (let i = 0; i < len - 1; i++) {
					for (let j = 0; j < len - 1 - i; j++) {
						if (list[j].value < list[j + 1].value) {
							// 相邻元素两两对比
							const temp = list[j + 1] // 元素交换
							list[j + 1] = list[j]
							list[j] = temp
						}
					}
				}
				return list
			},

			// 获取会员距离现在还有多少天
			remainingDays(dateTime) {
				const nowDate = new Date()
				// const nowDate = new Date('2021-07-18')
				const date =
					(new Date(dateTime).getTime() - nowDate.getTime()) /
					(1000 * 60 * 60 * 24)
				return parseInt(date) + 1 > 0
			},

			exportExcel(list) {
				if (!this.remainingDays(this.$store.state.info.StoreVipTime)) {
					this.$message.warning("认证商户才可导出")
					return
				}
				// console.log(list)
				if (list.length === 0) {
					this.$message.warning("暂无数据")
					return
				}
				const exportList = [["名称", "价格"]]
				list.forEach((item) => {
					let price = +item.value
					const changePrice = this.priceChangeList[this.adjustOptionVal]
					if (this.adjustOptionVal === 0) {
						price += changePrice
					} else if (price < 200 && this.adjustOptionVal === 1) {
						price += changePrice
					} else if (
						price >= 200 &&
						price < 300 &&
						this.adjustOptionVal === 2
					) {
						price += changePrice
					} else if (
						price >= 300 &&
						price < 400 &&
						this.adjustOptionVal === 3
					) {
						price += changePrice
					} else if (
						price >= 400 &&
						price < 500 &&
						this.adjustOptionVal === 4
					) {
						price += changePrice
					} else if (
						price >= 500 &&
						price < 600 &&
						this.adjustOptionVal === 5
					) {
						price += changePrice
					} else if (
						price >= 600 &&
						price < 700 &&
						this.adjustOptionVal === 6
					) {
						price += changePrice
					} else if (
						price >= 700 &&
						price < 800 &&
						this.adjustOptionVal === 7
					) {
						price += changePrice
					} else if (price >= 800 && this.adjustOptionVal === 8) {
						price += changePrice
					}
					exportList.push([item.Title, price > 0 ? price : 0])
				})
				const sheet = XLSX.utils.aoa_to_sheet(exportList)
				this.openDownloadDialog(this.sheet2blob(sheet), "报价单.xlsx")
			},

			sheet2blob(sheet, sheetName) {
				sheetName = sheetName || "sheet1"
				var workbook = {
					SheetNames: [sheetName],
					Sheets: {}
				}
				workbook.Sheets[sheetName] = sheet
				// 生成excel的配置项
				var wopts = {
					bookType: "xlsx", // 要生成的文件类型
					bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
					type: "binary"
				}
				var wbout = XLSX.write(workbook, wopts)
				var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" })
				// 字符串转ArrayBuffer
				function s2ab(s) {
					var buf = new ArrayBuffer(s.length)
					var view = new Uint8Array(buf)
					for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
					return buf
				}
				return blob
			},

			openDownloadDialog(url, saveName) {
				if (typeof url === "object" && url instanceof Blob) {
					url = URL.createObjectURL(url) // 创建blob地址
				}
				var aLink = document.createElement("a")
				aLink.href = url
				aLink.download = saveName || "" // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
				var event
				if (window.MouseEvent) event = new MouseEvent("click")
				else {
					event = document.createEvent("MouseEvents")
					event.initMouseEvent(
						"click",
						true,
						false,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					)
				}
				aLink.dispatchEvent(event)
			},

			saveBas64Img(url, name) {
				let base64 = url.toString()
				let byteCharacters = atob(
					base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
				)
				let byteNumbers = new Array(byteCharacters.length)
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}
				let byteArray = new Uint8Array(byteNumbers)
				let blob = new Blob([byteArray], {
					type: undefined
				})
				let aLink = document.createElement("a")
				aLink.download = `${name}.png` //这里写保存时的图片名称
				aLink.href = URL.createObjectURL(blob)
				aLink.click()
			},

			// 导出报表
			exportTable(lists) {
				if (!this.remainingDays(this.$store.state.info.StoreVipTime)) {
					this.$message.warning("认证商户才可导出")
					return
				}

				if (lists.length === 0) {
					this.$message.warning("暂无数据")
					return
				}

				const dpr = window.devicePixelRatio
				console.log(dpr)
				const list = this.modifyList(lists)

				const canvas = document.getElementById("canvasId")

				const column = 4 // 列数
				const len = list.length + column // 数组长度
				const line = Math.ceil(len / column) > 48 ? Math.ceil(len / column) : 48 // 行数
				const lineHeight = 20 // 行高度
				const columnWidth = 172 // 列宽度
				const priceWidth = 64 // 价格列
				const nameWidth = columnWidth - priceWidth // 名称列
				const canvasWidth = column * columnWidth // 宽度
				const canvasHeight = line * lineHeight // 高度
				const startHeight = 60 // 标题 + 日期 + 10留白高度
				const titleHeight = 20 // 标题高度

				canvas.width = (canvasWidth + 20) * dpr
				canvas.height = (canvasHeight + startHeight + 10) * dpr
				canvas.style.width = `${canvasWidth + 20}px`
				canvas.style.height = `${canvasHeight + startHeight + 10}px`

				const ctx = canvas.getContext("2d")
				console.log(canvas, canvas.width, canvas.style.width)

				this.drawTable(
					ctx,
					startHeight * dpr,
					titleHeight * dpr,
					canvasWidth * dpr,
					canvasHeight * dpr,
					columnWidth * dpr,
					lineHeight * dpr,
					priceWidth * dpr,
					nameWidth * dpr,
					dpr * 0.8
				) // 画表格

				this.drawFont(
					ctx,
					startHeight * dpr,
					line,
					list,
					columnWidth * dpr,
					lineHeight * dpr,
					priceWidth * dpr,
					nameWidth * dpr,
					dpr * 0.8
				) // 名称 + 价格

				this.exportImg = canvas.toDataURL("image/png", 1)
				this.saveBas64Img(this.exportImg, "报价")
			},

			drawTable(
				ctx,
				startHeight,
				titleHeight,
				canvasWidth,
				canvasHeight,
				columnWidth,
				lineHeight,
				priceWidth,
				nameWidth,
				dpr
			) {
				ctx.fillStyle = "#ffffff"
				ctx.fillRect(0, 0, canvasWidth + 20, canvasHeight + startHeight + 10) // 白色底

				ctx.strokeStyle = "#000000"
				ctx.lineWidth = 1
				ctx.strokeRect(10, startHeight, canvasWidth, canvasHeight) // 外圈矩形

				ctx.stroke()

				// const lineHeight = 20 // 每行高度
				const x = canvasHeight / lineHeight // 计算行数
				for (let i = 1; i < x; i++) {
					ctx.beginPath()

					ctx.moveTo(10, lineHeight * i + startHeight)
					ctx.lineTo(canvasWidth + 10, lineHeight * i + startHeight)

					ctx.stroke()
				}

				ctx.font = `${14 * dpr}px sans-serif`

				// columnWidth 每列宽度
				const y = canvasWidth / columnWidth

				for (let column = 0; column < y; column++) {
					ctx.beginPath()

					ctx.moveTo(10 + columnWidth * column, startHeight)
					ctx.lineTo(10 + columnWidth * column, canvasHeight + startHeight) // 类名列

					ctx.moveTo(10 + nameWidth + columnWidth * column, startHeight)
					ctx.lineTo(
						10 + nameWidth + columnWidth * column,
						canvasHeight + startHeight
					) // 名称列

					ctx.moveTo(10 + columnWidth * column, startHeight)
					ctx.lineTo(10 + columnWidth * column, canvasHeight + startHeight) // 价格列

					ctx.stroke()
				}

				ctx.strokeRect(10, 10, canvasWidth, startHeight - titleHeight - 10)
				ctx.strokeRect(10, startHeight - titleHeight, canvasWidth, titleHeight)

				ctx.beginPath()

				ctx.moveTo(10 + columnWidth * 3, 10)
				ctx.lineTo(10 + columnWidth * 3, startHeight - titleHeight)

				ctx.stroke()

				ctx.font = `${16 * dpr}px sans-serif`
				ctx.fillStyle = "black"
				ctx.textAlign = "center" // 字体居中
				ctx.textBaseline = "middle" // 字体水平居中
				const date = this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}")
				ctx.fillText(
					date,
					10 + columnWidth * 3 + (priceWidth + nameWidth) / 2,
					10 + (startHeight - 10 - titleHeight) / 2
				) // 日期

				ctx.fillStyle = "red"
				ctx.fillText(
					"报价表",
					10 + (canvasWidth - columnWidth) / 2,
					10 + (startHeight - 10 - titleHeight) / 2
				)

				if (this.title) {
					ctx.font = `${12 * dpr}px sans-serif`
					ctx.fillStyle = "black"
					ctx.fillText(
						this.title,
						10 + canvasWidth / 2,
						startHeight - titleHeight / 2
					)
				}
			},

			drawFont(
				ctx,
				startHeight,
				line,
				list,
				columnWidth,
				lineHeight,
				priceWidth,
				nameWidth,
				dpr
			) {
				ctx.font = `${14 * dpr}px sans-serif`
				console.log(ctx.font)
				ctx.textAlign = "center" // 字体居中
				ctx.textBaseline = "middle" // 字体水平居中

				const categoryList = []

				let column = 0 // 绘制字体的当前列
				let dIndex = 0 // 绘制字体的当前行

				// console.log(ctx, startHeight, line, list, columnWidth, lineHeight, priceWidth, nameWidth)
				// let price = 0;
				// if (this.value === "上调") {
				//   price = this.price - 0;
				// } else {
				//   price = 0 - this.price;
				// }
				// console.log('price', price)

				ctx.fillStyle = "black"
				list.forEach((item) => {
					if (item["category"]) {
						if (dIndex - column * line >= line - 1) {
							column++
							dIndex++
						}

						const c_w = 10 + column * columnWidth
						const c_h = startHeight + lineHeight * (dIndex - column * line)

						categoryList.push({
							text: item["category"],
							lw: c_w + nameWidth,
							lh: c_h,
							w: c_w + columnWidth / 2,
							h: c_h + lineHeight / 2
						})

						dIndex++
					} else {
						if (dIndex - column * line >= line) {
							column++
						}

						// ctx.fillStyle('black')
						const n_w = 10 + nameWidth / 2 - 2 + column * columnWidth
						const n_h =
							startHeight +
							lineHeight / 2 +
							lineHeight * (dIndex - column * line)
						ctx.fillText(item.Title, n_w, n_h, nameWidth)

						const p_w = 10 + nameWidth + priceWidth / 2 + column * columnWidth
						const p_h =
							startHeight +
							lineHeight / 2 +
							lineHeight * (dIndex - column * line)

						let price = +item.value
						const changePrice = this.priceChangeList[this.adjustOptionVal]
						if (this.adjustOptionVal === 0) {
							price += changePrice
						} else if (price < 200 && this.adjustOptionVal === 1) {
							price += changePrice
						} else if (
							price >= 200 &&
							price < 300 &&
							this.adjustOptionVal === 2
						) {
							price += changePrice
						} else if (
							price >= 300 &&
							price < 400 &&
							this.adjustOptionVal === 3
						) {
							price += changePrice
						} else if (
							price >= 400 &&
							price < 500 &&
							this.adjustOptionVal === 4
						) {
							price += changePrice
						} else if (
							price >= 500 &&
							price < 600 &&
							this.adjustOptionVal === 5
						) {
							price += changePrice
						} else if (
							price >= 600 &&
							price < 700 &&
							this.adjustOptionVal === 6
						) {
							price += changePrice
						} else if (
							price >= 700 &&
							price < 800 &&
							this.adjustOptionVal === 7
						) {
							price += changePrice
						} else if (price >= 800 && this.adjustOptionVal === 8) {
							price += changePrice
						}
						ctx.fillText(price > 0 ? price : 0, p_w, p_h, priceWidth)

						dIndex++
					}
				})

				ctx.fillStyle = "red"
				ctx.strokeStyle = "#ffffff"
				ctx.lineWidth = 3
				categoryList.forEach((item) => {
					ctx.beginPath()

					ctx.moveTo(item.lw - 0.5, item.lh + 0.5)
					ctx.lineTo(item.lw - 0.5, item.lh + lineHeight - 1)

					ctx.stroke()
					ctx.fillText(item.text, item.w, item.h)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-dialog__footer {
		padding: 10px 20px !important;
	}

	div {
		box-sizing: border-box;
	}

	.export {
		// width: 1126px;
		margin: 0 auto;

		.canvas {
			position: absolute;
			top: -10000px;
			left: 0;
		}

		.content {
			width: 100%;

			.adjust {
				margin-top: 10px;

				display: flex;
				align-items: center;
				justify-content: center;

				.adjust_btn {
					margin-left: 10px;

					width: 60px;
					height: 32px;

					text-align: center;
					line-height: 32px;

					cursor: pointer;

					border: 1px solid #b0b0b0;

					&_active {
						background-color: #66b1ff;
						color: #fff;
					}
				}
			}

			.list {
				width: 816px;
				margin: 10px auto;
				display: flex;
				flex-wrap: wrap;

				.list_item {
					height: 40px;
					box-sizing: border-box;
					padding: 4px 12px 4px 0;
					display: flex;
					align-items: center;

					.list_item_name {
						width: 100px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
		}

		.tabs {
			width: 600px;

			.tabs_tit {
				width: 600px;
				height: 50px;
				background: rgba(248, 248, 248, 1);
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: space-around;

				.tabs_name {
					width: 600px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: #5d5d5d;

					.tabs_name_item {
						flex: 1;
						height: 50px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}

				.active {
					color: #d83e26 !important;
				}
			}
		}

		.choose_list {
			width: 100%;

			.title_name {
				width: 100%;
				height: 26px;
				line-height: 26px;
				cursor: pointer;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.title_name_active {
				color: red;
			}

			.offer_name {
				height: 26px;
				line-height: 26px;
			}
		}
	}
</style>

